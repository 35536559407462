body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  @media all and (display-mode: fullscreen) {
    body {
        overflow: hidden;
      }
  }